import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import LinkButton from "./LinkButton";
import { kebabCase } from "lodash";

const CategoryGridTemplate = ({ data, count }) => {
  const { edges: categories } = data.allMarkdownRemark;

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
      {categories.map(({ node: category }) => {
        return (
          <div
            key={category.frontmatter.title}
            className="bg-primary p-6 rounded-lg text-white text-center flex flex-col gap-5"
          >
            <Link
              to={`/tags/${kebabCase(category.frontmatter.title)}`}
              className="block hover:text-secondary text-lg font-poppins"
            >
              {category.frontmatter.title}
            </Link>
            <p className="">{category.frontmatter.description}</p>
            <LinkButton
              addedStyle={`inline-block mt-auto`}
              link={`/tags/${kebabCase(category.frontmatter.title)}`}
            >
              Read More
            </LinkButton>
          </div>
        );
      })}
    </div>
  );
};

CategoryGrid.propTypes = {
  data: PropTypes.shape({
    allTagsJson: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function CategoryGrid() {
  return (
    <StaticQuery
      query={graphql`
        query CategoryGrid {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "tags" } } }
            sort: { fields: [frontmatter___title], order: ASC }
          ) {
            edges {
              node {
                frontmatter {
                  title
                  description
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <CategoryGridTemplate data={data} count={count} />
      )}
    />
  );
}
