import React from "react";
import { Link } from "gatsby";

const LinkButton = ({ children, link, addedStyle }) => {
  return (
    <Link
      className={`bg-secondary text-white px-3 py-2 text-sm rounded-full border-secondary border-2 hover:bg-white hover:text-secondary ${addedStyle}`}
      to={link}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
