import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import RecentPost from "./RecentPost";

const BlogRollTemplate = ({ data, count, limit }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="">
      {posts &&
        posts.slice(0, limit).map(({ node: post }, index) => (
          <div className="" key={post.frontmatter.title}>
            {(index % 2 === 0 && (
              <RecentPost postData={post} justify="left" />
            )) || <RecentPost postData={post} justify="right" />}
          </div>
        ))}
    </div>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll({ limit }) {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            totalCount
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  mainTag
                  date(formatString: "MMMM DD, YYYY")
                  description
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(width: 1000)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <BlogRollTemplate data={data} count={count} limit={limit} />
      )}
    />
  );
}
