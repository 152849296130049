import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import BlogRoll from "../components/BlogRoll";
import CategoryGrid from "../components/CategoryGrid";
import { kebabCase } from "lodash";
import LinkButton from "../components/LinkButton";
import MailChimpForm from "../components/MailChimpForm";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const IndexPageTemplate = ({ title, featuredPost }) => {
  return (
    <div className="">
      <Helmet>
        <meta
          name="google-site-verification"
          content="QvniFHviJgq_L3kyUdCZMHKa7jQlUzrvKCNc_2MSc_s"
        />
      </Helmet>
      <div
        title="Featured Post"
        className="flex-col lg:flex-row pb-8 md:pb-20 px-4 md:px-16 lg:px-32 flex items-center"
      >
        <div className="lg:w-2/3 xl:w-3/5">
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredPost.frontmatter.featuredimage,
              alt: `featured image thumbnail for post ${featuredPost.frontmatter.title}`,
              width:
                featuredPost.frontmatter.featuredimage.childImageSharp
                  .gatsbyImageData.width,
              height:
                featuredPost.frontmatter.featuredimage.childImageSharp
                  .gatsbyImageData.height,
            }}
          />
        </div>
        <div className="lg:w-3/5 xl:w-1/2 p-8 bg-white shadow-lg mx-4 -mt-8 lg:my-8 lg:-ml-12 z-10 rounded-sm flex flex-col text-sm xl:text-base">
          <Link
            to={`/tags/${kebabCase(featuredPost.frontmatter.mainTag)}`}
            className="font-poppins pb-2 text-lighttext hover:text-primary"
          >
            {featuredPost.frontmatter.mainTag}
          </Link>
          <h3 className="font-poppins text-xl xl:text-2xl font-semibold mb-4">
            <Link to={featuredPost.fields.slug} className="hover:text-primary">
              {featuredPost.frontmatter.title}
            </Link>
          </h3>
          <p className="font-poppins text-lighttext  pb-4">
            {featuredPost.frontmatter.description}
          </p>
          <div className="mt-auto flex justify-start items-center">
            <p className="font-poppins text-lighttext font-light italic">
              {featuredPost.frontmatter.date}
            </p>
            <Link
              to={featuredPost.fields.slug}
              className="ml-auto text-primary hover:text-secondary"
            >
              Read More <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </div>
      <div
        title="Email Subscription"
        className="py-8 md:py-20 px-4 md:px-16 lg:px-64 text-center bg-primary text-white"
      >
        <h2 className="font-poppins font-semibold text-2xl">
          Join our Community
        </h2>
        <p className="px-12 py-3">
          Subscribe to our email list to get important updates and new posts
          sent directly to your inbox!
        </p>
        <MailChimpForm />
      </div>
      <div
        title="Post Categories"
        className="py-8 md:py-20 px-4 md:px-16 lg:px-64"
      >
        <h2 className="text-2xl font-poppins font-semibold mb-6">Categories</h2>
        <CategoryGrid />
      </div>
      <div
        title="Recent Posts"
        className="py-8 md:py-20 px-4 md:px-16 xl:px-32 bg-lightgray"
      >
        <h2 className="text-2xl font-poppins font-semibold pb-8">
          Recently Posted
        </h2>
        <BlogRoll limit={10} />
        <div className="flex justify-center">
          <LinkButton link={`/blog`}>
            See All <FontAwesomeIcon icon={faArrowRight} />
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.pageData;
  const featuredPost = data.featuredPost;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        featuredPost={featuredPost}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($feature: String) {
    pageData: markdownRemark(
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        title
      }
    }
    featuredPost: markdownRemark(frontmatter: { title: { eq: $feature } }) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        mainTag
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
